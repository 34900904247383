.deliveryMethod {
  padding: 4px;
  border-radius: 32px;
  text-align: center;
  font-weight: 700;
  color: white;


  &.productStorePickup {
    background-color: blue;
  }

  &.boxnow {
    background-color: rgb(46, 191, 46);
  }
  &.shippingProvider {
    background-color: red;
  }

  &.bagStorePickup {
    background-color: rgb(4, 149, 252);
  }

  &.vanSlot {
    background-color: rgb(141, 63, 7);
  }

  &.boughtInStore {
    background-color: green;
  }
}