.widgetsTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-size: 20px;
  margin-bottom: 16px;
  background-color: #6c9fd9;
  color: black;
}

.widgetsRow {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-bottom: 32px;
  row-gap: 32px;
}
