.priceContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 16px;
}

.lock {
  margin-top: 8px;
}

.imageTitle {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  background-color: bisque;
  padding: 8px;
  border-radius: 16px;
  margin-bottom: 8px;
  margin-top: 8px;
  color: black;
}

.featuredIcon {
  font-size: 20px;
}

.fileTable {
  width: 100%;

  td, th {
    border: 1px solid #c4c3c3;
    text-align: left;
    padding: 8px;
    text-align: center;
  }
}

.copyButton {
  min-width: 32px !important;
}

.title {
  font-size: 16px;
  font-weight: 500;
  padding-top: 4px;
  padding-bottom: 12px;
}

.buttonText {
  font-size: 14px !important;
}

.diviver {
  width: 100%;
  border-top: 2px solid #c2c2c2;
  margin: 12px;
}

.featured {
  cursor: pointer;
  font-size: 24px;
}

.salePrice {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 8px;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 8px;
  align-items: center;
}

.compressionSettings {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 16px;
  background-color: var(--ra-palette-background-paper);
  border: 1px solid var(--ra-palette-divider);
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12);
}

.compressionOption {
  min-width: 150px;

  & .optionLabel {
    color: var(--ra-palette-text-primary);
  }

  & .optionInput {
    width: 120px;

    :global(.MuiInputBase-input) {
      color: var(--ra-palette-text-primary);
      background-color: var(--ra-palette-background-default);
    }

    :global(.MuiOutlinedInput-notchedOutline) {
      border-color: var(--ra-palette-divider);
    }
  }
}

.webWorkerOption {
  display: flex;
  align-items: center;

  :global(.MuiFormControlLabel-label) {
    color: var(--ra-palette-text-primary);
  }
}

.compressionToggles {
  display: flex;
  gap: 36px;
  padding: 8px;
  background-color: var(--ra-palette-background-paper);
  border: 1px solid var(--ra-palette-divider);
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12);

  :global(.MuiFormControlLabel-label) {
    color: var(--ra-palette-text-primary);
  }
}
