.countText {
  font-size: 4.5rem;
  font-weight: 500;
  color: rgb(255, 42, 0);
  margin: 1.5rem;
  text-align: center;
}

.loadingText {
  font-size: 1.5rem;
  font-weight: 500;
  color: rgb(0, 0, 255);
  margin: 1.5rem;
  text-align: center;
}

.subText {
  font-size: 1.2rem;
  text-align: center;
}
