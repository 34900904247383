.widgetsTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-size: 20px;
  margin-bottom: 16px;
  background-color: #6082a9;
  color: white;
}

.widgetsRow {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  row-gap: 32px;
  margin-bottom: 64px;
}

.title {
  font-size: 30px;
  text-align: center;
}