.widgetColumn {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  border-radius: 16px;
  border: 3px solid gray;
  padding: 16px;
}

.widgetTitle {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
}

.widgetSubtitle {
  text-align: center;
  font-size: 18px;
  max-width: 250px;
}
