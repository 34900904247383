.statusText {
  padding: 8px;
  border-radius: 32px;
  text-align: center;
  font-weight: 700;
  color: white;

  &.rejected, &.cancelled, &.failed {
    background-color: red;
  }

  &.picked_up {
    background-color: rebeccapurple;
  }

  &.pending_payment {
    background-color: orange;
  }

  &.shipped, &.completed{
    background-color: green;
  }

  &.paid, &.created {
    background-color: blue;
  }

  &.evaluation {
    background-color: gray;
  }
}

.googleMapsIcon {
  content:url(./googleMaps.png);
  max-width: 28px;
}