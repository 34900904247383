.as-image {
    &.rounded img {
        border-radius: 50%;
    }

    &.small img{
        width: 80px;
        height: 80px;
    }
}

.background-image {
    height: 55px;
    width: 48px;
    background-size: contain;
    background-repeat: no-repeat;
}

.MuiFormControl-root {
    width: 256px;
}

th {
    background: #f7f7f7;
}

th, td {
    padding: 10px;
}

.helper-text {
    width: 100% !important;
    color: #9e9e9e;
    font-size: 15px;
    margin-bottom: 10px;
}

.create-page[resource=bagorders] #id-helper-text {
    display: none;
}

.border {
    border: 1px solid grey;
    border-radius: 15px;
}

.filter-selection {
    width: 100%;
    padding: 15px;
}

.single-category {
    max-width: 1200px;

    .select-box {
        max-width: 200px;
    }

    .category-options {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        div { margin: 5px; }
    }

    .remove-button {
        cursor: pointer;
        display: inline-block;
        background-color: red;
        color: white;
        padding: 5px;
        line-height: 6px;
        border-radius: 50%;
        height: 10px;
        width: 10px;
        vertical-align: middle;
        text-align: center;
        font-size: 25px;
    }
}

.select-box {
    max-height: 300px;
    overflow-y: auto;
    padding: 10px;
    overflow-x: hidden;

    .item {
        cursor: pointer;
    }
}
